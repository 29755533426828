import { Injectable } from '@angular/core';
import { ClientsUsersRolesRequest } from '@app/shared/interfaces/clients-users-roles/clients-users-roles.request';
import { ClientsUsersRoles } from '@app/shared/interfaces/clients-users-roles/clients-users-roles.response';
import { AbstractCrudService } from '../../abstract/abstract-crud.service';

@Injectable()
export class ClientsRolesService extends AbstractCrudService<
  ClientsUsersRoles,
  ClientsUsersRolesRequest
> {
  protected override feature = 'clients-users-roles';
  protected override endPoint = `/clients-users-roles/`;
}
